<!--消息管理-报警信息-->
<template>
  <div class="public_participation_page">
    <el-form inline size="mini">
      <el-form-item>
        <el-select
          placeholder="消息类型"
          v-model="filter.msgType"
          clearable
          @change="searchData"
        >
          <el-option value="ExceedStandard" label="超标报警"></el-option>
          <el-option value="AbnormalOffline" label="异常离线报警"></el-option>
          <el-option value="FilterAbnormallyNoUse" label="不正常使用净化器"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-cascader
          :style="filter.owner.length ===0? '':'width:300px'"
          placeholder="所属单位"
          @change="searchData"
          v-model="filter.owner"
          :options="customerTree"
          :props="customerProps"
          clearable
          filterable
        />
      </el-form-item>
<!--6月7号新增消息管理-报警消息时间区间选择器-->
      <el-form-item>
        <el-date-picker
          type="datetimerange"
          v-model="filter.AlarmAt"
          @change="netGetMessageList"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="timestamp"
          range-separator="至"
          :start-placeholder=defaultStart
          :end-placeholder=defaultEnd
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="searchData"
        >查询
        </el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      ref="table"
      size="mini"
      border
      style="width: 100%;"
      @selection-change="selsChange"
      :max-height="clientHeight"
      :data="dataObj.list"
      v-loading="loading"
    >
      <el-table-column type="selection"/>
      <el-table-column
        type="index"
        label="序号"
        align="center"
        width="55"
      />
      <el-table-column
        prop="AcquitAt"
        :formatter="dateFormat"
        label="时间"
        align="center"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="MsgType"
        label="消息类型"
        align="center"
        width="120"
      >
        <template slot-scope='{ row }'>
          <span>{{ row.MsgType | typeFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="监测点"
        align="center"
        width="200"
      >
        <template slot-scope='{ row }'>
          <span>{{ getLocaleNameFromContent(row.Content) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="Content"
        label="消息内容"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="120"
        fixed="right"
        header-align="center"
      >
        <template slot-scope="scope">
          <i
            title="发送消息"
            @click="handleSendMsg(scope.row)"
            class="opt el-icon-message"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="page_box">
      <el-button
        type="primary"
        size="mini"
        @click="batchSend"
        :disabled="selRows.length === 0"
      >批量发送
      </el-button>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="filter.page"
        :page-sizes="[20, 40, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataObj.total"
      ></el-pagination>
    </div>

    <el-dialog
      width="25%"
      :close-on-click-modal="false"
      :visible.sync="dlg.visible"
      @close="handleDialogClose"
    >
      <el-form
        :model="formData"
        label-width="124px"
        ref="form"
        size="mini"
      >
        <el-row>
          <el-form-item
            prop="LocaleName"
            label="监测点名称"
          >
            <el-input
              disabled
              v-model.trim="formData.LocaleName"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item
            prop="Content"
            label="消息内容"
          >
            <el-input
              type="textarea"
              v-model.trim="formData.Content"
            ></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="dlg.visible = false"
        >取消
        </el-button
        >
        <el-button type="primary" size="mini" @click.native="handleSubmit" :loading="loading"
        >提交
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {dateFormater, getUserInfo, ownerJoin, join, maintenanceTypeOptions, referToMap} from '@/util/index'
import {mapState} from 'vuex'

export default {
  name: 'AlarmMessage',
  data() {
    return {
      defaultStart:'',//默认开始时间
      defaultEnd:'',//默认结束时间
      filter: {
        owner: [],
        StartAt: 0,
        page: 1,
        size: 20,
        AlarmAt:'',//日期选择栏时间
      },
      loading: false,
      // 列表数据
      dataObj: {
        list: [],
        total: 0
      },
      selRows: [],
      dlg: {
        visible: false,
        submitted: false
      },
      formData: {
        LocaleName: '',
        Content: ''
      },
    }
  },
  mounted() {
    this.userInfo = getUserInfo().Info
    this.init();
    //自动获取当前时间
    this.getDayTime();
  },
  filters: {
    typeFilter(type) {
      const op = {
        'ExceedStandard': '超标报警',
        'AbnormalOffline': '异常离线报警',
        'FilterAbnormallyNoUse':'不正常使用净化器'
      }
      return op[type]
    }
  },
  computed: {
    ...mapState({
      customerProps: (state) =>
        Object.assign({}, state.props, {label: 'Org', checkStrictly: true})
    }),
    ...mapState(['customerTree','clientHeight']),
  },
  methods: {
    getDayTime(){//作用是拿到今天的时间戳，转换成年-月-日
      //当天0点0分0秒
      const beginJab = new Date(new Date().toLocaleDateString()).getTime()/1000
      //第二天0点0分0秒
      const endJab =(new Date(new Date().toLocaleDateString()).getTime()/1000) + 24 * 60 * 60 -1
      var date = new Date(beginJab*1000); // 注意：JavaScript Date对象需要的是毫秒时间戳
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2); // getMonth返回的月份是从0开始的，所以需要+1
      var day = ("0" + date.getDate()).slice(-2);
      var hours = ("0" + date.getHours()).slice(-2);
      var minutes = ("0" + date.getMinutes()).slice(-2);
      var seconds = ("0" + date.getSeconds()).slice(-2);
      const begin = (year + "-" + month + "-" + day+ "-" + hours+ "-" + minutes+ "-" + seconds);
      var date = new Date(endJab * 1000);
      let Y = date.getFullYear() + "-"; //年
      let M =(date.getMonth() + 1 < 10? "0" + (date.getMonth() + 1): date.getMonth() + 1) + "-"; // 月
      let D =(date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " "; //日
      let h =(date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":"; //时
      let m =(date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +":"; //分
      let s =date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); //秒

      const end =(Y + M + D + h + m + s);

      this.defaultStart = begin;
      this.defaultEnd = end;
    },
    init() {
      this.loading = true
      this.netGetMessageList() // 获取短信列表
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示')
            .then(() => {
              this.dlg.submitted = true
              this.loading = true
              let param = new FormData()
              param.append("ids",this.formData.Id)
              param.append("sendType","alarm")
              this.$post('admin/sendOneMessage', param)
                .then((res) => {
                  this.$message.success(res)
                  this.dlg.visible = false
                  this.loading = false
                })
                .catch(() => {
                  this.loading = false
                })
            })
        }
      })
    },
    getLocaleNameFromContent(content) {
      const strs = content.match(/\【(.+?)\】/g)
      if (Array.isArray(strs) && strs) {
        return strs[0]
      }
      return ''
    },
    handleRefresh(clearFilter = false) {
      if (clearFilter) {
        this.filter.page = 1
        this.filter.StartAt = 0
      }
      this.netGetMessageList()
    },
    handleShowDialog() {
      this.dlg.visible = true
      this.dlg.submitted = false
    },
    /**
     * @param submitted 如果是提交后关闭，则刷新
     */
    handleDialogClose() {
      if (this.dlg.submitted) {
        this.changeState = false
        this.handleRefresh(false)
      }
      this.formData = {
        LocaleName: '',
        Content: ''
      }
    },
    handleSendMsg(row) {
      this.formData = {
        LocaleName: this.getLocaleNameFromContent(row.Content),
        ...row
      }
      this.handleShowDialog()
    },
    clearTableSelections() {
      this.$refs.table.clearSelection()
    },
    /**
     * @description 批量发送
     */
    batchSend() {
      var ids = []
      for(var i = 0; i < this.selRows.length; i++) {
        ids.push(this.selRows[i].Id)
      }
      let param = new FormData()
      param.append("ids",ids.join(","))
      param.append("sendType","alarm")
      this.$confirm('确认批量发送吗？', '提示', {
        type: 'warning'
      })
        .then(() => {
          this.$post('admin/sendOneMessage', param)
            .then((res) => {
              this.$message.success(res)
              this.clearTableSelections()
            })
            .catch(() => {
            })
        })
        .catch((e) => {
          this.$message.error('发送出错')
          console.log(e)
        })
    },
    /**
     * @description 表格列选中事件
     * @param sels
     */
    selsChange(sels) {
      this.selRows = sels
    },
    /**
     * @description 获取消息列表
     */
    netGetMessageList() {
      this.loading = true
      const filter = {}
      filter.StartAt = this.filter.size * (this.filter.page - 1)
      filter.Size = this.filter.size
      filter.Param = {}
      if (this.filter.msgType) {
        filter.Param['msg_type'] = this.filter.msgType
      }
      if (this.filter.owner && this.filter.owner.length > 0) {
        filter.Param['owner'] = `${ownerJoin(this.filter.owner, this.userInfo)}`
      }
      //6月7号新增按时间区间查询
      //当天0点0分0秒
      const todayBegin = new Date(new Date().toLocaleDateString()).getTime()/1000
      //第二天0点0分0秒
      const todayEnd = (new Date(new Date().toLocaleDateString()).getTime()/1000) + 24 * 60 * 60 -1
      if (this.filter.AlarmAt===''){//判断时间区间选择器有没有选择时间
        filter.Param["begin"]=todayBegin//没有选择则执行默认今天的时间
        filter.Param["end"]=todayEnd
      }else if(this.filter.AlarmAt===null){//当用户选择时间后，可以清除时间区间选择器，清除后默认查询到全部的数据
        this.defaultStart="开始时间"
        this.defaultEnd="结束时间"
      }else {
        filter.Param["begin"] = this.filter.AlarmAt[0]/1000
        filter.Param["end"] = this.filter.AlarmAt[1]/1000
      }
      this.$post('admin/listAlarmMsg', filter)
        .then(res => {
          this.dataObj.list = res.content
          this.dataObj.total = res.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property], true)
    },
    /**
     * @description 查询
     */
    searchData() {
      this.filter.page = 1
      this.netGetMessageList()
    },

    /**
     * @description 每页条数
     */
    handleSizeChange(Size) {
      this.filter.size = Size
      this.netGetMessageList()
    },

    /**
     * @description 页码
     */
    handleCurrentChange(page) {
      this.filter.page = page
      this.netGetMessageList()
    }
  }
}
</script>
<style lang="scss" scoped>
.public_participation_page {
  .page_box {
    width: auto;
    padding-top: 8px;
    display: flex;
    justify-content: flex-start;

    > div {
      flex: 1;
    }
  }

  .form_item_desc {
    .el-form-item__content {
      padding: 10px 0;
    }
  }
}
</style>
